<template>
    <div
        class="dashboard-container organize-wrapper"
        :class="{ 'section-show': isSectionShow }"
    >
        <div class="dashboard-title">
            組織人力
            <div class="info-icon" :tooltip="tips.title" tooltip-touch />
        </div>
        <div v-if="isLoading" class="process-loading-icon" />
        <div
            v-if="!isError"
            class="bar-chart"
            :class="{ 'hide-chart': isLoading }"
        >
            <div class="chart-header">
                <div></div>
                <div class="left-one">已登錄</div>
                <div class="right-one">已報聘</div>
            </div>
            <div
                v-for="(val, key) in orgManpowerData"
                :key="key"
                class="bar"
                :class="`${key}-bar`"
            >
                <div class="bar-title">{{ key }}</div>
                <div class="bar-fill">
                    <!-- =============================== 總人數 =============================== -->
                    <div
                        class="total-bar"
                        @mouseover="showTooltip(key, 'total')"
                        @mouseleave="hideTooltip()"
                        @touchend="checkIsActive(key, 'total')"
                    >
                        <div
                            v-show="tips.chart[key].total"
                            class="tooltip"
                            @click.stop="hideTooltip()"
                            :style="{
                                left: chartTooltipLeft(
                                    val.applyCount,
                                    totalMember
                                )
                            }"
                        >
                            {{ $numberWithComma(totalMember) }} 人
                        </div>
                    </div>
                    <!-- =============================== 已報聘人數 =============================== -->
                    <div
                        class="main-bar"
                        :style="{
                            width: rateToPercent(val.applyRate, 0)
                        }"
                        @mouseover.stop="showTooltip(key, 'main')"
                        @mouseleave.stop="hideTooltip()"
                        @touchend.stop="checkIsActive(key, 'main')"
                    >
                        <div
                            v-show="tips.chart[key]?.main"
                            class="tooltip"
                            @click.stop="hideTooltip()"
                            :style="{
                                left: chartTooltipLeft(
                                    val.registerCount,
                                    val.applyCount
                                )
                            }"
                        >
                            {{ $numberWithComma(val.applyCount) }} 人
                        </div>
                    </div>
                    <!-- =============================== 已登錄人數 ===============================-->
                    <div
                        class="sub-bar"
                        :style="{
                            width: rateToPercent(val.registerRate, 0)
                        }"
                        @mouseover.stop="showTooltip(key, 'sub')"
                        @mouseleave.stop="hideTooltip()"
                        @touchend.stop="checkIsActive(key, 'sub')"
                    >
                        <div
                            v-show="tips.chart[key].sub"
                            class="tooltip"
                            @click.stop="hideTooltip()"
                            :style="{ left: '80%' }"
                        >
                            {{ $numberWithComma(val.registerCount) }} 人
                        </div>
                    </div>
                </div>
                <div class="percentage">
                    <span
                        class="left-one"
                        @mouseenter="showTooltip(key, 'register')"
                        @mouseleave="hideTooltip()"
                        @touchend="checkIsActive(key, 'register')"
                    >
                        <div
                            v-show="tips.chart[key].register"
                            class="tooltip"
                            @click.stop="hideTooltip()"
                        >
                            已登錄{{ key }}人數/全部人數
                        </div>
                        {{ rateToPercent(val.registerRate, '00.00%') }}
                    </span>
                    <span
                        class="right-one"
                        @mouseenter="showTooltip(key, 'apply')"
                        @mouseleave="hideTooltip()"
                        @touchend="checkIsActive(key, 'apply')"
                    >
                        <div
                            class="tooltip"
                            v-show="tips.chart[key].apply"
                            @click.stop="hideTooltip()"
                        >
                            已報聘{{ key }}人數/全部人數
                        </div>
                        {{ rateToPercent(val.applyRate, '00.00%') }}
                    </span>
                </div>
            </div>
        </div>
        <div v-if="!isLoading && !isError" class="summary">
            <div v-for="(val, key) in summaryList" :key="key" class="list-item">
                <div class="item-summary">
                    <div
                        class="title"
                        :class="key === 'all' ? 'all' : `${key}`"
                    >
                        <div class="main"></div>
                        <div v-show="key !== 'all'" class="sub"></div>
                        <div class="name">
                            {{ key === 'all' ? '全部' : key }}
                        </div>
                    </div>
                    <div v-if="key === 'all'" class="count">
                        {{ $numberWithComma(totalMember) }}
                        <span>人</span>
                    </div>
                    <div v-else class="count">
                        {{
                            `${$numberWithComma(
                                val.registerCount
                            )}/${$numberWithComma(val.applyCount)}`
                        }}
                        <span>人</span>
                    </div>
                </div>
                <div class="link-icon" @click="queryOnManpowerPage(key)"></div>
            </div>
        </div>
        <DashboardErrorMessage
            v-if="isError && !isLoading"
            functionName="getDashboardOrganizationManpowerAPI"
            @reacquire="getOrganizeData"
        />
    </div>
</template>
<script>
import DashboardErrorMessage from '@/containers/home/DashboardErrorMessage.vue'
import { getDashboardOrganizationManpowerAPI } from '@/assets/javascripts/api.js'

import _ from 'lodash'

export default {
    name: 'DashboardOrganizationManpower',
    components: { DashboardErrorMessage },
    methods: {
        getOrganizeData: async function () {
            this.isLoading = true
            this.isError = false

            try {
                let res = await getDashboardOrganizationManpowerAPI()
                this.$store.commit('set', {
                    dashboardOrganizationManpower: res.data
                })
            } catch (e) {
                this.$store.commit('set', {
                    dashboardOrganizationManpower: null
                })
                this.isError = true
            } finally {
                this.isLoading = false
            }
        },
        showTooltip: function (org, item) {
            this.hideTooltip()
            this.tips.chart.mouseover = { org, item }
            this.tips.chart[org][item] = true
        },
        hideTooltip: function () {
            _.assign(
                this.tips.chart,
                _.mapValues(this.tips.chart, (tip) =>
                    _.mapValues(tip, () => false)
                )
            )
            this.tips.chart.mouseover = { org: '', item: '' }
        },
        queryOnManpowerPage: function (rank) {
            this.$setGaEvent(`filterOrganization_${rank}`, 'cross-page-filter')
            let payload = {}
            if (rank === 'all') {
                payload = this.linkPayload.total
            } else {
                payload = this.linkPayload.detail[rank]
            }
            payload.role = payload.role ?? 'supervisor'
            this.$crossPageFilter('Organization', payload, {}, true)
        },
        checkIsActive: function (org, item) {
            let mouseover = this.tips.chart.mouseover
            if (mouseover.org === org && mouseover.item === item) {
                this.hideTooltip()
            } else {
                this.showTooltip(org, item)
            }
        },
        rateToPercent: function (rate, noData) {
            return `${rate.toFixed(2)}%` || noData
        },
        chartTooltipLeft: function (inner, outer) {
            let rate = inner / outer
            return rate < 0.8 ? '80%' : '99%'
        }
    },
    computed: {
        summaryList: function () {
            return { all: {}, ...this.orgManpowerData }
        },
        isSectionShow: function () {
            return !this.$store.state.isSectionHidden
        },
        orgManpowerData: function () {
            return this.$store.state?.dashboardOrganizationManpower?.detail
        },
        totalMember: function () {
            return this.$store.state?.dashboardOrganizationManpower?.total
        },
        linkPayload: function () {
            return this.$store.state?.dashboardOrganizationManpower?.payload
        }
    },
    data() {
        return {
            tips: {
                title: '當日組織內的人力',
                chart: {
                    dm: {
                        total: false,
                        main: false,
                        sub: false,
                        apply: false,
                        register: false
                    },
                    bm: {
                        total: false,
                        main: false,
                        sub: false,
                        apply: false,
                        register: false
                    },
                    cm: {
                        total: false,
                        main: false,
                        sub: false,
                        apply: false,
                        register: false
                    },
                    ca: {
                        total: false,
                        main: false,
                        sub: false,
                        apply: false,
                        register: false
                    },
                    mouseover: { org: '', item: '' }
                }
            },
            isLoading: false,
            isError: false
        }
    },
    async mounted() {
        if (
            this.$store.state.dashboardOrganizationManpower === null ||
            (typeof this.$store.state.dashboardOrganizationManpower ===
                'object' &&
                Object.keys(this.$store.state?.dashboardOrganizationManpower)
                    .length === 0) ||
            this.$shouldUpdateHomeApi()
        ) {
            await this.getOrganizeData()
        }
    }
}
</script>
<style lang="scss" scoped>
@mixin tooltip($bottom, $position) {
    .tooltip {
        position: absolute;
        bottom: $bottom;
        background: rgba(0, 0, 0, 0.6);
        padding: 8px 10px;
        border-radius: 5px;
        color: $primary-white;
        font-size: 12px;
        text-align: center;
        line-height: 14px;
        width: max-content;
        z-index: 10;
        &::after {
            content: '';
            position: absolute;
            top: 100%;

            border-style: solid;
            border-width: 4px 3px 0 3px;
            border-color: rgba(0, 0, 0, 0.6) transparent transparent transparent;
            @if $position == 'left' {
                left: 0%;
                transform: translateX(200%);
            }
            @if $position == 'right' {
                right: 0%;
                transform: translateX(-200%);
            }
        }
    }
}

@mixin bar($main, $sub) {
    .bar-fill {
        @include tooltip(calc(100% + 8px), 'left');
        place-self: stretch;
        position: relative;

        .total-bar,
        .main-bar,
        .sub-bar {
            height: 100%;
            position: absolute;
            border-radius: 5px;
            top: 0;
            left: 0;
        }

        .main-bar,
        .sub-bar {
            width: 0%;
            transition: 0.75s;
        }

        .total-bar {
            background: $primary-grey;
            width: 100%;
        }

        .main-bar {
            background: $main;
        }

        .sub-bar {
            background: $sub;
        }

        .tooltip {
            transform: translate(-15px, 15px);
        }
    }
}

@mixin grid-template($column, $center, $gap) {
    display: grid;
    grid-template-columns: $column;
    gap: $gap;
    @if $center == true {
        place-items: center;
    }
}

%apply-register {
    @include tooltip(calc(100% + 2px), 'right');
    font-size: 12px;
    width: 100%;
    font-family: 'Roboto';
    &.right-one,
    &.left-one {
        cursor: default;
        position: relative;
        line-height: 20px;
    }

    &.left-one {
        padding-right: 5px;
        text-align: end;
        line-height: 20px;
    }

    &.right-one {
        padding-left: 5px;
        position: relative;
        text-align: end;
        &::before {
            content: '';
            background: $ninth-grey;
            width: 1px;
            height: 12px;
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }
    }

    .tooltip {
        right: calc(50% - 14px);
    }
}

@mixin flex($justify, $align) {
    display: flex;
    justify-content: $justify;
    align-items: $align;
}

@mixin summary-icon($main, $sub) {
    .main,
    .sub {
        width: 15px;
        height: 15px;
        border-radius: 3px;
    }

    .main {
        background: $main;
    }

    .sub {
        background: $sub;
    }
}

.organize-wrapper {
    display: flex;
    flex-direction: column;
    color: $sixth-black;
    padding: 10px 20px;
    position: relative;
    text-transform: uppercase;
    &.dashboard-container {
        min-height: 251px;
    }

    &.section-show {
        padding: 10px;
    }

    * {
        box-sizing: border-box;
    }

    .dashboard-title {
        .info-icon {
            margin-left: 5px;
            width: 14px;
            height: 14px;
        }
    }

    .bar-chart {
        margin-bottom: 10px;
        &.hide-chart {
            opacity: 0;
        }

        .chart-header {
            @include grid-template(1fr 50px 50px, false, 0px);
            height: 20px;
            div {
                @extend %apply-register;
            }
        }

        .bar {
            @include grid-template(40px 1fr 100px, true, 5px);
            height: 20px;
            margin-bottom: 15px;
            .bar-title {
                font-size: 14px;
            }

            &.dm-bar {
                @include bar($dashboard-pink-lighter, $dashboard-pink);
            }

            &.bm-bar {
                @include bar($dashboard-purple-disabled, $dashboard-purple);
            }

            &.cm-bar {
                @include bar($dashboard-blue-lighter, $fourth-blue);
            }

            &.ca-bar {
                @include bar($dashboard-yellow-lighter, $dashboard-yellow);
            }

            .percentage {
                @include grid-template(50px 50px, true, 0px);
                span {
                    @extend %apply-register;
                    &.left-one {
                        text-align: end;
                    }
                }
            }
        }
    }

    .summary {
        @include flex(space-between, center);
        .list-item {
            @include flex(space-between, center);
            flex: 1 1 auto;
            height: 41px;
            min-width: 105px;
            max-width: 130px;

            .item-summary {
                display: flex;
                flex-direction: column;
                flex: 1 1 auto;
                margin-right: 10px;
                .title {
                    @include flex(space-between, center);
                    font-size: 12px;
                    width: 61px;
                    margin-left: auto;
                    &.all {
                        @include summary-icon($primary-grey, none);
                    }

                    &.dm {
                        @include summary-icon(
                            $dashboard-pink,
                            $dashboard-pink-lighter
                        );
                    }

                    &.bm {
                        @include summary-icon(
                            $dashboard-purple,
                            $dashboard-purple-disabled
                        );
                    }

                    &.cm {
                        @include summary-icon(
                            $fourth-blue,
                            $dashboard-blue-lighter
                        );
                    }

                    &.ca {
                        @include summary-icon(
                            $dashboard-yellow,
                            $dashboard-yellow-lighter
                        );
                    }
                }

                .count {
                    text-align: end;
                    font-weight: 500;
                    span {
                        font-weight: 400;
                        font-size: 12px;
                    }
                }
            }

            .link-icon {
                cursor: pointer;
                flex-shrink: 0;
                background-image: url('~@/assets/images/dashboard/btn_circlearrow_right@3x.webp');
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                width: 20px;
                height: 20px;
            }
        }

        @media screen and (max-width: 1017px) {
            @include grid-template(repeat(auto-fit, 130px), false, 20px 0);
        }
    }

    @media screen and (max-width: 1280px) {
        padding: 10px;
    }
}

.process-loading-icon {
    box-sizing: border-box;
    position: absolute;
    top: 45%;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 36px;
    height: 36px;
}

.error-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
